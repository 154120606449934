import { useSIPProvider } from "data/context/Sip";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SessionState } from "sip.js";
import { HistoryPhone } from "types/Phone";

const usePhone = () => {
  const [search, setSearch] = useState<string>("");
  const [historyPhone, setHistoryPhone] = useState<HistoryPhone[]>([]);
  const [groupedHistory, setGroupedHistory] = useState<
    Record<string, HistoryPhone[]>
  >({});
  const [filteredHistory, setFilteredHistory] = useState<
    Record<string, HistoryPhone[]>
  >({});

  const [activeTab, setActiveTab] = useState<"recent" | "contact" | "dialog">(
    "recent"
  );
  const [modalShowKeyboard, setModalShowKeyboard] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [modalShowCalling, setModalShowCalling] = useState<boolean>(false);
  const [modalCallIncoming, setModalCallIncoming] = useState<boolean>(false);
  const [callOutGoing, setCallOutGoing] = useState<boolean>(false);
  const [nameCallOutGoing, setNameCallOutGoing] = useState<string>("");
  const { sessionManager, sessions } = useSIPProvider();

  const handleCallIncoming = useCallback((value: boolean) => {
    setModalCallIncoming(value);
  }, []);

  useEffect(() => {
    if (Object.values(sessions).length === 0) return;
    let firstSession = Object.values(sessions)[0];
    console.error(firstSession.state);
    if (firstSession.state === "Initial" && callOutGoing === false) {
      handleCallIncoming(true);
      setPhoneNumber(firstSession.remoteIdentity.displayName || "");
      const sessions = JSON.parse(localStorage.getItem("sessions") || "[]");
      const newSession = {
        id: firstSession.id,
        phone: firstSession.remoteIdentity.displayName,
        direction: "INCOMING",
        second: 0,
        startTime: new Date().toISOString(),
      };

      localStorage.setItem(
        "sessions",
        JSON.stringify([newSession, ...sessions])
      );
    } else {
      handleCallIncoming(false);
    }
  }, [sessions, handleCallIncoming, callOutGoing]);

  useEffect(() => {
    const filteredHistory = Object.entries(groupedHistory)
      .map(([date, calls]) => {
        const filteredCalls = calls.filter((call) => {
          const phone = (call.phone || "").toLowerCase(); // Đưa về chữ thường
          const name = (call.name || "").toLowerCase();
          const searchLower = search.toLowerCase();

          return phone.includes(searchLower) || name.includes(searchLower);
        });

        return filteredCalls.length > 0 ? [date, filteredCalls] : null;
      })
      .filter(Boolean);

    setFilteredHistory(
      Object.fromEntries(
        filteredHistory.filter(
          (entry): entry is [string, HistoryPhone[]] => entry !== null
        )
      )
    );
  }, [groupedHistory, search]);

  const handleSetShowModalCallOutGoing = useCallback((value: boolean) => {
    setModalShowCalling(value);
  }, []);

  const handleSetShowKeyBoard = useCallback(
    (value: boolean, clearPhoneNumber?: boolean) => {
      if (clearPhoneNumber) {
        setPhoneNumber("");
      }
      setModalShowKeyboard(value);
    },
    []
  );

  const handleClickCall = useCallback(
    (phone?: string, name?: string) => {
      const numberToCall = phone || phoneNumber;

      setNameCallOutGoing(name || "");

      if (!numberToCall) {
        console.error("Số điện thoại không hợp lệ.");
        return;
      }

      setCallOutGoing(true);
      sessionManager?.call(`sip:${numberToCall}@vtp0004067.cfvn.cloud`, {});
      handleSetShowKeyBoard(false);
      handleSetShowModalCallOutGoing(true);
    },
    [
      handleSetShowKeyBoard,
      handleSetShowModalCallOutGoing,
      phoneNumber,
      sessionManager,
    ]
  );

  const handleSetActiveTab = useCallback((value: "recent" | "contact") => {
    setActiveTab(value);
  }, []);

  const handleSetHistory = useCallback((value: HistoryPhone[]) => {
    setHistoryPhone(value);
  }, []);

  const groupByDate = useCallback((data: HistoryPhone[]) => {
    return data.reduce((acc: Record<string, HistoryPhone[]>, item) => {
      if (item.startTime) {
        const date = new Date(item.startTime).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
      }
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    const getDataHistoryPhoneFromStorage = () => {
      const data = localStorage.getItem("sessions");
      if (data) {
        const parsedData: HistoryPhone[] = JSON.parse(data);
        handleSetHistory(parsedData);

        const groupedData = groupByDate(parsedData);
        setGroupedHistory(groupedData);
      }
    };

    // Call the function
    getDataHistoryPhoneFromStorage();
  }, [groupByDate, handleSetHistory]);

  const handleSetPhone = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value);
    },
    []
  );

  const handleAddNumber = useCallback((number: string) => {
    setPhoneNumber((prev) => prev + number);
  }, []);

  const handleRemoveNumber = useCallback(() => {
    setPhoneNumber((prev) => prev.slice(0, -1));
  }, []);

  const handleClickCallFromHistory = useCallback(
    (phone: string | undefined) => {
      if (!phone) return;
      setCallOutGoing(true);
      sessionManager?.call(`sip:${phone}@vtp0004067.cfvn.cloud`, {});
      setPhoneNumber(phone);
      handleSetShowModalCallOutGoing(true);
    },
    [handleSetShowModalCallOutGoing, sessionManager]
  );

  const handleClearNumber = useCallback(() => {
    setPhoneNumber("");
  }, []);

  return useMemo(
    () => ({
      filteredHistory,
      nameCallOutGoing,
      activeTab,
      search,
      historyPhone,
      groupedHistory,
      modalShowKeyboard,
      phoneNumber,
      modalShowCalling,
      modalCallIncoming,
      callOutGoing,
      setGroupedHistory,
      handleClickCall,
      handleAddNumber,
      handleRemoveNumber,
      handleSetShowKeyBoard,
      handleSetPhone,
      handleSetActiveTab,
      handleSetShowModalCallOutGoing,
      handleClickCallFromHistory,
      handleCallIncoming,
      handleClearNumber,
      setPhoneNumber,
    }),
    [
      filteredHistory,
      nameCallOutGoing,
      activeTab,
      search,
      historyPhone,
      groupedHistory,
      modalShowKeyboard,
      phoneNumber,
      modalShowCalling,
      modalCallIncoming,
      callOutGoing,
      setGroupedHistory,
      handleClickCall,
      handleAddNumber,
      handleRemoveNumber,
      handleSetShowKeyBoard,
      handleSetPhone,
      handleSetActiveTab,
      handleSetShowModalCallOutGoing,
      handleClickCallFromHistory,
      handleCallIncoming,
      handleClearNumber,
    ]
  );
};

export default usePhone;
