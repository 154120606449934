import { IMAGES_APP } from "assets";
import { useSessionCall } from "data/context/Sip";
import { styles } from "./styles";
import { SessionState } from "sip.js";
import { CallTimer } from "../CallTimer";
import { useCallback, useEffect, useRef, useState } from "react";
import { HistoryPhone } from "types/Phone";

interface CallActionUIProps {
  sessionId: string;
  handleSetShowModalCallOutGoing: (value: boolean) => void;
  phoneNumber: string;
  setGroupedHistory: (value: Record<string, HistoryPhone[]>) => void;
  nameCallOutGoing: string;
}

const CallActionUI = ({
  sessionId,
  handleSetShowModalCallOutGoing,
  phoneNumber,
  setGroupedHistory,
  nameCallOutGoing,
}: CallActionUIProps) => {
  const {
    isHeld,
    isMuted,
    decline,
    hangup,
    hold,
    mute,
    answer,
    session,
    unhold,
    unmute,
    direction,
    timer,
  } = useSessionCall(sessionId);

  const [second, setSecond] = useState<number>(0);

  const handleSetSecond = useCallback((value: number) => {
    setSecond(value);
  }, []);

  // Ref cho audio
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const groupByDate = useCallback((data: HistoryPhone[]) => {
    return data.reduce((acc: Record<string, HistoryPhone[]>, item) => {
      if (item.startTime) {
        const date = new Date(item.startTime).toLocaleDateString();
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(item);
      }
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    if (session.state === SessionState.Establishing) {
      audioRef.current?.play();

      const sessions = JSON.parse(localStorage.getItem("sessions") || "[]");

      const newSession = {
        id: session.id,
        phone: phoneNumber,
        direction,
        second: 0,
        startTime: new Date().toISOString(),
        name: nameCallOutGoing,
      };

      localStorage.setItem(
        "sessions",
        JSON.stringify([newSession, ...sessions])
      );
      const groupedData = groupByDate([newSession, ...sessions]);
      setGroupedHistory({ ...groupedData });
    } else {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    }
  }, [
    nameCallOutGoing,
    session.state,
    phoneNumber,
    session.id,
    direction,
    groupByDate,
    setGroupedHistory,
  ]);

  useEffect(() => {
    if (session.state === SessionState.Terminated) {
      const sessions = JSON.parse(localStorage.getItem("sessions") || "[]");

      const updatedSessions = sessions.map((item: any) =>
        item.id === session.id ? { ...item, second } : item
      );

      localStorage.setItem("sessions", JSON.stringify(updatedSessions));

      const groupedData = groupByDate(updatedSessions);
      setGroupedHistory({ ...groupedData });

      //Close UI Calling
      handleSetShowModalCallOutGoing(false);
    }
  }, [
    session.state,
    session.id,
    second,
    groupByDate,
    setGroupedHistory,
    handleSetShowModalCallOutGoing,
  ]);

  return (
    <div>
      <audio ref={audioRef} src="/ringtone_outgoing.mp3" loop />

      {session.state === SessionState.Establishing && <p>Calling...</p>}
      <p>
        {timer?.answeredAt && (
          <CallTimer
            isEnd={session.state === SessionState.Terminated}
            startAt={timer.answeredAt}
            handleSetSecond={handleSetSecond}
          />
        )}
      </p>
      {![SessionState.Terminating, SessionState.Terminated].includes(
        session.state
      ) && (
        <img
          onClick={() => {
            hangup();
            handleSetShowModalCallOutGoing(false);
          }}
          src={IMAGES_APP.cancelBtn}
          alt="cancel"
          style={styles.iconCancelBtn}
        />
      )}
    </div>
  );
};

export default CallActionUI;
