import Phone from "pages/Phone";
import "./App.css";
import { SIPProvider } from "data/context/Sip";
import { Routes, Route, HashRouter } from "react-router-dom";
import KeyBoardPhone from "pages/KeyBoardPhone";
import InCalling from "pages/InCalling";
import { useEffect, useState } from "react";
import { Account } from "types/MatBao/account";

function App() {
  const [loadingGetListMatBao, setLoadingGetListMatBao] = useState(true);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

  useEffect(() => {
    const fetchListMatBao = async () => {
      try {
        const responseRAW = await fetch(
          "https://api.cloudfone.vn/api/CloudFone/AccountsInfo",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              ServiceCode: "VTP0004067",
              AuthUser: "MBO012735",
              AuthKey: "31ad93c8-ae6a-49d8-8d20-469a6a255f72",
            }),
          }
        );
        const response = await responseRAW.json();
        const accounts = response.data;

        if (accounts && accounts.length > 0) {
          const randomIndex = Math.floor(Math.random() * accounts.length);
          console.log("Selected account:", accounts[randomIndex]);
          setSelectedAccount(accounts[randomIndex]);
        }
      } catch (error) {
        console.error("Error fetching or parsing data:", error);
      } finally {
        setLoadingGetListMatBao(false);
      }
    };

    fetchListMatBao();
  }, []);

  if (loadingGetListMatBao) {
    return <div>Loading...</div>;
  }

  if (!selectedAccount) {
    return <div>No accounts available</div>;
  }

  return (
    <div className="App">
      <SIPProvider
        options={{
          domain: "vtp0004067.cfvn.cloud",
          webSocketServer: "wss://rtc.cloudfone.vn:4433",
          userName: selectedAccount.id,
          password: selectedAccount.data,
        }}
      >
        <HashRouter>
          <Routes>
            <Route path="/calling/:phone" element={<InCalling />} />
            <Route path="keyboard-phone" element={<KeyBoardPhone />} />
            <Route path="*" element={<Phone />} />
          </Routes>
        </HashRouter>
      </SIPProvider>
    </div>
  );
}

export default App;
