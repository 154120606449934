export const styles: { [key: string]: React.CSSProperties } = {
  wrapperBTNAction: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: "25rem",
  },
  iconCancelBtn: {
    width: "4rem",
  },
};
