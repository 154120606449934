import search from "./images/svg/search.svg";
import time from "./images/svg/time.svg";
import people from "./images/svg/people.svg";
import keyboardDialog from "./images/svg/keyboardDialog.svg";
import arrowLeft from "./images/svg/arrowLeft.svg";
import deleteText from "./images/svg/deleteText.svg";
import btnCall from "./images/svg/btnCall.svg";
import avatar from "./images/svg/avatar.svg";
import cancelBtn from "./images/svg/cancelBtn.svg";
import callOutgoing from "./images/svg/call_outgoing.svg";
import callIncoming from "./images/svg/call_incoming.svg";
import phone_icon from "./images/svg/phone_icon.svg";
import answer_icon from "./images/svg/answer_icon.svg";

export const IMAGES_APP = {
  search,
  time,
  people,
  keyboardDialog,
  arrowLeft,
  deleteText,
  btnCall,
  avatar,
  cancelBtn,
  callOutgoing,
  callIncoming,
  phone_icon,
  answer_icon,
};
