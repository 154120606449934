import { IMAGES_APP } from "assets";
import { useSessionCall } from "data/context/Sip";
import { styles } from "./styles";
import { useEffect, useRef } from "react";
import { SessionState } from "sip.js";

interface InComingUIProps {
  sessionId: string;
  handleSetShowModalCallOutGoing: (value: boolean) => void;
  handleSetToggleModalCallIngoing: (value: boolean) => void;
}

const InComingUI = ({
  sessionId,
  handleSetShowModalCallOutGoing,
  handleSetToggleModalCallIngoing,
}: InComingUIProps) => {
  const {
    isHeld,
    isMuted,
    decline,
    hangup,
    hold,
    mute,
    answer,
    session,
    unhold,
    unmute,
    direction,
    timer,
  } = useSessionCall(sessionId);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    if (session.state === SessionState.Initial) {
      audioRef.current?.play();
    } else {
      audioRef.current?.pause();
      audioRef.current!.currentTime = 0;
    }
  }, [session.state]);

  return (
    <div style={styles.wrapperBTNAction}>
      <audio ref={audioRef} src="/ringtone_outgoing.mp3" loop />
      <img
        onClick={() => {
          answer();
          handleSetShowModalCallOutGoing(true);
          handleSetToggleModalCallIngoing(false);
        }}
        src={IMAGES_APP.answer_icon}
        alt="Answer Icon"
        style={styles.iconCancelBtn}
      />
      <img
        onClick={() => {
          decline();
          handleSetToggleModalCallIngoing(false);
        }}
        src={IMAGES_APP.cancelBtn}
        alt="Answer Icon"
        style={styles.iconCancelBtn}
      />
    </div>
  );
};

export default InComingUI;
