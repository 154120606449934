export const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100vw",
    maxWidth: "430px",
    margin: "0 auto",
    overflow: "hidden",
    minHeight: "100vh",
    position: "relative",
  },
  wrapperSearchHeader: {
    margin: "24px",
    padding: "10px",
    border: "1px solid red",
    display: "flex",
    alignItems: "center",
    borderRadius: "2rem",
    gap: "0.8rem",
  },
  inputSearchHeader: {
    border: "none",
    outline: "none",
    flex: 1,
  },
  wrapperIConSearch: {
    width: "1.5rem",
    height: "1.5rem",
  },
  iconSearch: {
    width: "1.5rem",
    height: "auto",
  },
  wrapperBottomTab: {
    width: "100%",
    minHeight: "5rem",
    backgroundColor: "#F2EDE7",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-around",
  },
  iconBottomTab: {
    width: "1.2rem",
    height: "auto",
    padding: "0.4rem 1.5rem",
    borderRadius: "1rem",
  },
  iconBottomTabActive: {
    backgroundColor: "#fff",
  },
  wrapperIconBottomTab: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "0.5rem",
  },
  textSectionBottomTab: {
    fontSize: "0.8rem",
  },
  iconKeyboardDialog: {
    width: "1.8rem",
    height: "auto",
    position: "absolute",
    bottom: "6rem",
    right: "1rem",
    padding: "0.8rem",
    borderRadius: "1rem",
    backgroundColor: "#6E5B43",
    cursor: "pointer",
  },
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: "1rem",
    width: "90%",
    marginBottom: "1rem",
    color: "#fff",
    fontSize: "1rem",
  },
  modalBackButton: {
    border: "none",
    borderRadius: "0.5rem",
    cursor: "pointer",
    backgroundColor: "#fff",
  },
  modalBody: {
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "1rem",
    textAlign: "center",
    width: "90%",
  },
  phoneDisplay: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  phoneNumber: {
    fontSize: "1.5rem",
    textAlign: "center",
  },
  removeButton: {
    backgroundColor: "#f44336",
    color: "#fff",
    border: "none",
    borderRadius: "0.5rem",
    padding: "0.5rem 1rem",
    cursor: "pointer",
  },
  keypad: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "2rem",
    width: "100%",
    maxWidth: "300px",
    margin: "4rem auto 0",
  },
  key: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "4rem",
    height: "4rem",
    borderRadius: "50%",
    border: "1px solid #ccc",
    backgroundColor: "#f9f9f9",
    fontSize: "1.5rem",
    cursor: "pointer",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    transition: "background-color 0.2s ease, transform 0.2s ease",
  },
  keyActive: {
    backgroundColor: "#e0f7fa",
    transform: "scale(0.95)",
  },
  iconLeftBack: {
    width: "0.8rem",
  },
  iconDeleteText: {
    width: "2rem",
    height: "auto",
  },
  iconBtnCall: {
    width: "3rem",
    height: "auto",
    marginTop: "2rem",
  },
  avatar: {
    width: "8rem",
    borderRadius: "50%",
    marginTop: "2rem",
  },
  avatarHistory: {
    width: "3rem",
    borderRadius: "50%",
  },
  iconCancelBtn: {
    width: "4rem",
  },
  wrapperActionInCall: {
    paddingBottom: "15rem",
    display: "flex",
    flexDirection: "column",
    gap: "0.2rem",
  },

  wrapperHistoryByDate: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "1rem",
  },
  containerHistory: {
    maxHeight: "calc(100vh - 200px)",
    overflowY: "auto",
    padding: "1rem",
    backgroundColor: "#f9f9f9",
  },
  titleSectionList: {
    fontSize: "0.8rem",
  },
  wrapperItemHistoryCall: {
    display: "flex",
    gap: "1rem",
    alignItems: "center",
    width: "100%",
  },
  wrapperInforItemHistory: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "0.5rem",
    flex: 1,
  },
  textInforHistory: {
    fontSize: "0.8rem",
  },
  iconPhone: {
    width: "1rem",
  },
  iconPhoneIcon: {
    width: "1.5rem",
  },
  textPhone: {
    fontSize: "1.5rem",
    color: "#656565",
    textAlign: "center",
  },
  textInComing: {
    fontSize: "1.2rem",
    color: "#656565",
  },
};
