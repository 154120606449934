import { IMAGES_APP } from "assets";
import { memo } from "react";
import usePhone from "./usePhone";
import { styles } from "./styles";
import { useSIPProvider } from "data/context/Sip";
import { SessionDirection } from "types/Sip";
import CallActionUI from "./CallActionUI";
import { formatDate } from "utils/helpers";
import InComingUI from "./InComingUI";
import { removeSpaces } from "utils/text";

const Phone = () => {
  const {
    filteredHistory,
    nameCallOutGoing,
    activeTab,
    search,
    groupedHistory,
    modalShowKeyboard,
    phoneNumber,
    modalShowCalling,
    modalCallIncoming,
    callOutGoing,
    setGroupedHistory,
    handleClickCall,
    handleAddNumber,
    handleRemoveNumber,
    handleSetShowKeyBoard,
    handleSetPhone,
    handleSetActiveTab,
    handleSetShowModalCallOutGoing,
    handleClickCallFromHistory,
    handleCallIncoming,
    handleClearNumber,
    setPhoneNumber,
  } = usePhone();

  const { sessions } = useSIPProvider();

  const handlePickContact = async () => {
    if ("contacts" in navigator && "ContactsManager" in window) {
      try {
        const props = ["name", "email", "tel", "address", "icon"];
        const opts = { multiple: false }; // Chỉ chọn một liên hệ
        const contacts = await (navigator.contacts as any).select(props, opts);

        if (contacts.length > 0) {
          const contact = contacts[0]; // Lấy liên hệ đầu tiên
          const name = contact.name ? contact.name[0] : "Không có tên";
          const tel = contact.tel ? contact.tel[0] : "Không có số điện thoại";

          const isConfirmed = window.confirm(
            `Liên hệ với: ${name} (${tel}). Bạn có muốn tiếp tục?`
          );
          if (isConfirmed) {
            setPhoneNumber(removeSpaces(tel));
            handleClickCall(removeSpaces(tel), name);
          }
        } else {
          alert("Không có liên hệ nào được chọn.");
        }
      } catch (error) {
        console.error("Lỗi khi truy cập danh bạ:", error);
      }
    } else {
      console.error(
        "Contact Picker API không được hỗ trợ trên trình duyệt này."
      );
    }
  };

  return (
    <div style={styles.container}>
      {/* Header */}
      {/* Search Header */}
      <div style={styles.wrapperSearchHeader}>
        <div style={styles.wrapperIConSearch}>
          <img src={IMAGES_APP.search} alt="search" style={styles.iconSearch} />
        </div>
        <input
          type="text"
          placeholder="Find person or number"
          style={styles.inputSearchHeader}
          value={search}
          onChange={handleSetPhone}
        />
      </div>
      {/* Bottom Tab */}
      <div style={styles.wrapperBottomTab}>
        <div
          style={styles.wrapperIconBottomTab}
          onClick={() => handleSetActiveTab("recent")}
        >
          <img
            src={IMAGES_APP.time}
            alt="time"
            style={{
              ...styles.iconBottomTab,
              ...(activeTab === "recent" ? styles.iconBottomTabActive : {}),
            }}
          />
          <span style={styles.textSectionBottomTab}>Gần đây</span>
        </div>
        <div
          style={styles.wrapperIconBottomTab}
          onClick={() => handleSetShowKeyBoard(true)}
        >
          <img
            src={IMAGES_APP.keyboardDialog}
            alt="time"
            style={{
              ...styles.iconBottomTab,
              ...(activeTab === "contact" ? styles.iconBottomTabActive : {}),
            }}
          />
          <span style={styles.textSectionBottomTab}>Bàn phím</span>
        </div>
        <div style={styles.wrapperIconBottomTab} onClick={handlePickContact}>
          <img
            src={IMAGES_APP.people}
            alt="people"
            style={{
              ...styles.iconBottomTab,
              ...(activeTab === "dialog" ? styles.iconBottomTabActive : {}),
            }}
          />
          <span style={styles.textSectionBottomTab}>Danh bạ</span>
        </div>
      </div>

      {/* History */}
      <div style={styles.containerHistory}>
        {Object.entries(filteredHistory).map(([date, calls]) => (
          <div key={date} style={styles.wrapperHistoryByDate}>
            <p style={styles.titleSectionList}>{date}</p>
            {calls.map((call) => (
              <div key={call.id} style={styles.wrapperItemHistoryCall}>
                <div>
                  <img
                    src={IMAGES_APP.avatar}
                    alt="avatar"
                    style={styles.avatarHistory}
                  />
                </div>
                <div style={styles.wrapperInforItemHistory}>
                  <span style={styles.textInforHistory}>
                    {call.name || call.phone}
                  </span>
                  <span style={styles.textInforHistory}>
                    <img
                      src={
                        call.direction === SessionDirection.INCOMING
                          ? IMAGES_APP.callIncoming
                          : IMAGES_APP.callOutgoing
                      }
                      alt="phone"
                      style={styles.iconPhone}
                    />
                    <span> {formatDate(call.startTime)}</span>
                  </span>
                </div>
                <div
                  onClick={handleClickCallFromHistory.bind(Phone, call.phone)}
                >
                  <img
                    src={IMAGES_APP.phone_icon}
                    alt="phone"
                    style={styles.iconPhoneIcon}
                  />
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>

      {/* {Object.keys(sessions).map((sessionId) => (
        <CallSessionItem key={sessionId} sessionId={sessionId} />
      ))} */}

      {/* Keyboard Button */}
      {/* <Link to="/keyboard-phone">
        <img
          src={IMAGES_APP.keyboardDialog}
          alt="keyboard"
          style={styles.iconKeyboardDialog}
        />
      </Link> */}

      {/* Modal */}
      {modalShowKeyboard && (
        <div style={styles.modal}>
          <div style={styles.modalHeader}>
            <img
              src={IMAGES_APP.arrowLeft}
              alt="back"
              style={styles.iconLeftBack}
            />
            <button
              style={styles.modalBackButton}
              onClick={() => {
                handleSetShowKeyBoard(false, true);
              }}
            >
              Back
            </button>
          </div>
          {phoneNumber && (
            <button
              onClick={handleClearNumber}
              style={{
                backgroundColor: "#f44336",
                color: "#fff",
                border: "none",
                borderRadius: "0.5rem",
                padding: "0.5rem 1rem",
                cursor: "pointer",
              }}
            >
              Clear
            </button>
          )}
          <div style={styles.modalBody}>
            {/* Hiển thị số */}
            <div style={styles.phoneDisplay}>
              <span />
              <input
                type="number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  width: "60%",
                  borderBottom: "1px solid #000",
                  textAlign: "center",
                  fontSize: "1.5rem",
                  paddingBottom: "0.5rem",
                }}
              />
              <img
                onClick={handleRemoveNumber}
                src={IMAGES_APP.deleteText}
                alt="back"
                style={styles.iconDeleteText}
              />
            </div>

            {/* Bàn phím số */}
            <div style={styles.keypad}>
              {["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"].map(
                (key) => (
                  <button
                    key={key}
                    style={styles.key}
                    onClick={() => handleAddNumber(key)}
                  >
                    {key}
                  </button>
                )
              )}
            </div>

            {/* Nút gọi */}
            <div>
              <img
                onClick={() => handleClickCall()}
                src={IMAGES_APP.btnCall}
                alt="call"
                style={styles.iconBtnCall}
              />
            </div>
          </div>
        </div>
      )}
      {/* Modal Calling */}
      {modalShowCalling && (
        <div style={styles.modal}>
          <div>
            <img src={IMAGES_APP.avatar} alt="avatar" style={styles.avatar} />
          </div>
          <p>{phoneNumber}</p>
          <div style={styles.wrapperActionInCall}>
            <CallActionUI
              sessionId={Object.keys(sessions)[0]}
              handleSetShowModalCallOutGoing={handleSetShowModalCallOutGoing}
              phoneNumber={phoneNumber}
              setGroupedHistory={setGroupedHistory}
              nameCallOutGoing={nameCallOutGoing}
            />
          </div>
        </div>
      )}
      {/* Modal In Going */}
      {!callOutGoing && modalCallIncoming && (
        <div style={styles.modal}>
          <div>
            <img src={IMAGES_APP.avatar} alt="avatar" style={styles.avatar} />
          </div>
          <p>{phoneNumber}</p>
          <InComingUI
            sessionId={Object.keys(sessions)[0]}
            handleSetShowModalCallOutGoing={handleSetShowModalCallOutGoing}
            handleSetToggleModalCallIngoing={handleCallIncoming}
          />
        </div>
      )}
    </div>
  );
};

export default memo(Phone);
