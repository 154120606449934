export const formatDate = (isoString: string | undefined): string => {
  if (!isoString) return "";
  const date = new Date(isoString);

  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const dayOfWeek = ["CN", "Th 2", "Th 3", "Th 4", "Th 5", "Th 6", "Th 7"][
    date.getDay()
  ];

  return `${hours}:${minutes} ${dayOfWeek}`;
};
